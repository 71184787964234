import React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Heading } from 'theme-ui'
import icon from '../../../../../site/content/assets/favicon.png'
import { useNews } from '@helpers-blog'

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `contentBg`,
    borderRadius: `lg`,
    size: `full`,
    p: 4,
  },
  heading: {
    color: `omegaDark`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    wordSpacing: 500, //force line breaks
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3,
    }
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`,
  },
  list: {
    color: `#c12221`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2,
    },
  },
  link: {
    color: 'omegaDark',
    padding: '3px 6fpx',
    lineHeight: '1rem',
    background: 'transparent',
    mb: '6px',
    border: 'none',
    textAlign: 'right',
  },
  button: {
    display: `block`,
    mt: `auto`,
  },
  logo: {
    width: '35%',
    height: 'auto',
    margin: '4px',
  }
}

const BannerVertical = () => {
  const news = useNews()
  const category = news[0]?.category
  return (
    <Flex sx={styles.wrapper}>
      <Heading variant='h2' sx={styles.heading}>
        <img src={icon} style={styles.logo} />
        نيوز
      </Heading>
      <ul style={styles.list}>
        {news &&
          news.map(item => (
            <li key={item.id}>
              <Button as={Link} to={item.slug} sx={styles.link}>
                {item.title}
              </Button>
            </li>
          ))}
      </ul>
      <Button
        variant='primary'
        as={Link}
        to={category.slug}
        sx={styles.button}
        aria-label='Learn More'
      >
        اقرأ المزيد
      </Button>
    </Flex>
  )
}

export default BannerVertical
