import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Flex, Box, Button, Heading, Text } from 'theme-ui'

const styles = {
  wrapper: src => ({
    alignItems: `center`,
    flexDirection: [`column`, `row`],
    bg: `contentBg`,
    backgroundImage: src && [`none`, `none`, `url(${src})`],
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `50% bottom`,
    justifyContent: `center`,
    margin: 'auto',
    p: 4
  }),

  
}

const BannerHorizontal = ({ head, body, button }) => {
  const data = useStaticQuery(bannerHorizontalQuery)
  const { file } = data
  const banner = file && file.banner && file.banner.regular
  const adRef = useRef()

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
    script.async = true
    document.head.appendChild(script)

    script.onload = () => {
      if (!adRef.current.innerHTML) {
        ;(adsbygoogle = window.adsbygoogle || []).push({})
      }
    }

    return () => {
      document.head.removeChild(script)
    }
  }, [])
  return (
    <Flex sx={styles.wrapper(banner && banner.src)}>
      <ins
        ref={adRef}
        className='adsbygoogle'
        style={{
          display: 'block',
          maxWidth: '100%',
          width: '500px',
          height: '200px'
        }}
        data-ad-client={process.env.GATSBY_GOOGLE_ADSENSE_PUBLISHER_ID}
        data-ad-format='auto'
        data-full-width-responsive='false'
      ></ins>
    </Flex>
  )
}

const bannerHorizontalQuery = graphql`
  query BannerHorizontalQuery {
    file(absolutePath: { regex: "/banner.(jpeg|jpg|gif|png)/" }) {
      banner: childImageSharp {
        regular: resize(width: 150, height: 50, cropFocus: CENTER) {
          src
        }
      }
    }
  }
`

export default BannerHorizontal

BannerHorizontal.propTypes = {
  head: PropTypes.string, // must be a string and defined
  body: PropTypes.string, // must be a string and defined
  button: PropTypes.string // must be a string and defined
}