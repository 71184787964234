import React from 'react'
import { Link } from 'gatsby'
import { Heading, Flex, Text } from 'theme-ui'
import { useEditorial } from '@helpers-blog'
const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `contentBg`,
    borderRadius: `lg`,
    size: `full`,
    p: 4
  },
  heading: {
    color: `omegaDark`,
    display: 'flex',
    flexDirection: 'column',
    wordSpacing: 500, //force line breaks
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3
    }
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`
    }
  },
  link: {
    '&:hover': {
      color: 'beta'
    }
  },
  button: {
    display: `block`,
    mt: `auto`
  },
  logo: {
    width: '50%',
    height: 'auto',
    margin: '4px'
  }
}

const EditorialBanner = () => {
  const { allArticle, allContentfulEditorial } = useEditorial()

  // Check if allContentfulEditorial.enabled is true
  if (allContentfulEditorial && allContentfulEditorial.enabled) {
    return (
      <Flex sx={styles.wrapper}>
        <Heading
          variant='h4'
          as={Link}
          to={allArticle.category.slug}
          sx={styles.link}
        >
          {allArticle.category.name}
        </Heading>
        <Heading variant='h1' as={Link} to={allArticle.slug} sx={styles.link}>
          {allArticle.title}
        </Heading>
        {allArticle.author && (
          <Heading
            variant='h5'
            as={Link}
            to={allArticle.author.slug}
            sx={styles.link}
          >
            {allArticle.author.name}
          </Heading>
        )}
      </Flex>
    )
  } else {
    // Return null or some other placeholder component if allContentfulEditorial.enabled is false
    return null
  }
}

export default EditorialBanner
