import React, { useState, useEffect } from 'react'
import whatsappgroup from '../../../../../site/content/assets/whatsappgroup.jpg'

const styles = {
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.5rem'
  },
  img: {
    maxHeight: 'auto',
    cursor: 'pointer'
  }
}

const WhatsappBanner = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false)

  useEffect(() => {
    function handleResize() {
      setIsLargeScreen(window.innerWidth >= 1024)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClick = () => {
    window.location.href = 'https://chat.whatsapp.com/GMEvPxxSgTg0dTyP1l3sqz'
  }

  const imgStyle = isLargeScreen ? { width: '25%' } : { width: '50%' }

  return (
    <div style={styles.imgContainer}>
      <a
        style={styles.imgContainer}
        href='https://chat.whatsapp.com/GMEvPxxSgTg0dTyP1l3sqz'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          src={whatsappgroup}
          alt='oui'
          style={{ ...styles.img, ...imgStyle }}
          onClick={handleClick}
        />
      </a>
    </div>
  )
}

export default WhatsappBanner
