import React, { useState, useEffect } from 'react'
import downloadapp from '../../../../../site/content/assets/downloadapp.jpg'
import { Box } from 'theme-ui'

const styles = {
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.5rem'
  },
  img: {
    width: '50%', // Make the image responsive
    maxHeight: 'auto',
    cursor: 'pointer' // Add a pointer cursor to indicate the image is clickable
  }
}

const DownloadApp = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false)

  useEffect(() => {
    function handleResize() {
      setIsLargeScreen(window.innerWidth >= 1024)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClick = () => {
    window.location.href =
      'https://apps.apple.com/fr/app/newsalist/id6450201309?l=en-GB'
  }

  const imgStyle = isLargeScreen ? { width: '25%' } : { width: '50%' }

  return (
    <Box style={styles.imgContainer}>
      <a
        href='https://apps.apple.com/fr/app/newsalist/id6450201309?l=en-GB'
        target='_blank'
        rel='noopener noreferrer'
        style={styles.imgContainer}
      >
        <img
          src={downloadapp}
          alt='oui'
          style={{ ...styles.img, ...imgStyle }}
          onClick={handleClick}
        />
      </a>
    </Box>
  )
}

export default DownloadApp
