import React from 'react'
import { useYtbVideo } from '@helpers-blog'
import { Flex } from 'theme-ui'
const DailyQuote = () => {
  const { url } = useYtbVideo()

  return (
    <Flex
      sx={{
        padding: '4px 24px',
        minWidth: '0px',
        border: 'none',
        fontWeight: 'bold'
      }}
    >
      <iframe
        width='80%'
        style={{ aspectRatio: '16/9', margin: 'auto' }}
        src={url}
        frameBorder='0'
        allowFullScreen
      ></iframe>
    </Flex>
  )
}

export default DailyQuote
