import { graphql } from 'gatsby'
import PostsPage from '../containers/Posts'

export default PostsPage

export const pageQuery = graphql`
  query PostsPageQuery(
    $paginatePostsPage: Boolean!
    $skip: Int
    $limit: Int
    $includeExcerpt: Boolean!
    $includeTimeToRead: Boolean! = false
    $imageQuality: Int!
  ) {
    featuredPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        featured: { eq: true },
        category: { id: { nin: ["0c982496-8e9e-5e3e-9360-ac8c329ddee0", "04b1cc4d-74c5-590c-a096-3c6c5b1a501a", "1864d925-4a34-55f4-ab5e-b0c435da233f"] } }
      }
      sort: { fields: [date], order: DESC }
      limit: 10
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailFeatured
      }
    }

    recentPosts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        featured: { eq: true }
        category: { id: { nin: ["0c982496-8e9e-5e3e-9360-ac8c329ddee0", "04b1cc4d-74c5-590c-a096-3c6c5b1a501a", "1864d925-4a34-55f4-ab5e-b0c435da233f"] } }
      }
      sort: { fields: [date], order: DESC }
      limit: 8
      skip: 3
    ) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
    }

    posts: allArticle(
      filter: {
        private: { ne: true }
        draft: { ne: true }
        category: { id: { nin: ["0c982496-8e9e-5e3e-9360-ac8c329ddee0", "04b1cc4d-74c5-590c-a096-3c6c5b1a501a"] } }
      }
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      group(field: category___name, limit: 10) {
        categoryName: fieldValue
        nodes {
          ...ArticlePreview
          ...ArticleThumbnailRegular
        }
      }
    }

    paginatedPosts: allArticle(
      filter: { private: { ne: true }, draft: { ne: true } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $paginatePostsPage) {
      nodes {
        ...ArticlePreview
        ...ArticleThumbnailRegular
      }
      ...ArticlePagination
    }
  }
`
