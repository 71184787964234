// extracted by mini-css-extract-plugin
export var articleText = "NewArticleBar-module--articleText--2eedd";
export var articles = "NewArticleBar-module--articles--d1d6a";
export var author = "NewArticleBar-module--author--853c2";
export var blink = "NewArticleBar-module--blink--bc8c2";
export var blinkingnews = "NewArticleBar-module--blinkingnews--332e5";
export var container = "NewArticleBar-module--container--756aa";
export var date = "NewArticleBar-module--date--e1fc7";
export var dates = "NewArticleBar-module--dates--2c1ea";
export var description = "NewArticleBar-module--description--274be";
export var img = "NewArticleBar-module--img--5aaeb";
export var live = "NewArticleBar-module--live--4ccc3";
export var title = "NewArticleBar-module--title--71043";
export var titles = "NewArticleBar-module--titles--2c35f";